import GenericService from "../../services/GenericService";
import FormSelectSearchModal from "../forms/base/FormSelectSearchModal";


export default class SelectGeneric extends FormSelectSearchModal {

    constructor(props) {
        super(props);
        
        this.getOptions = this.getOptions.bind(this);

        this.state.showBtns = this.props.showBtns === undefined ? true : this.props.showBtns;
        this.state.showBtnSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
        //this.state.showBtnNewForm = this.state.showBtns && ((this.props.showBtnNewForm === undefined ? true : false) && Permissao.getInstance().getPermissao(33));
        //this.state.showBtnNewSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
    }

    objEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }

        return true;
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if(nextProps.filter!== undefined && this.props.filter !== undefined){
            if(!this.objEqual(nextProps.filter, this.props.filter)){
                this.getOptions();
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptions(() => {
            if (this.dispachChangeDidMount && this.props.value) {
                this.handleChange({ target: { value: this.props.value, name: this.props.name } });
            }
        });
        
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {        
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        var controller = this.props.controller !== undefined ? this.props.controller : "error";

        if(this.props.getOptionsWhen === undefined || this.props.getOptionsWhen(this.props.filter)){
            GenericService.getForCombo(controller, filter, (response) => {
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();
                    }
                });
            });
        }
    }

    render() {
        return super.render();
    }
}