import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EventBus from "../../controllers/EventBus";
import MyComponent from "../../components/custom/MyComponent";
import FaturaService from "../../services/FaturaService";
import Util from "../../utils/Util";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import InputDate from "../../components/forms/elements/InputDate";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import InputNumber from "../../components/forms/elements/InputNumber";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import SelectMes from "../../components/app/SelectMes";
import SelectProprietarioByUsuario from "../../components/app/SelectProprietarioByUsuario";
import BoletoService from "../../services/BoletoService";
import FormBaixa from "../Baixa/FormBaixa"
import BtnButton from "../../components/forms/elements/BtnButton";
import CobrancaService from "../../services/CobrancaService";
import FormRelatorioFatura001 from "../Relatorio/FormRelatorioFatura001";
import MostraValor from "../../components/app/MostraValor";
import { toast } from "react-toastify";
import Select from "../../components/forms/elements/Select";
import InputText from "../../components/forms/elements/InputText";
import Permissao from "../../controllers/Permissao";
import FormRelatorioFinanceiro002 from "../Relatorio/FormRelatorioFinanceiro002";
import FormFaturaAcordo from "./FormFaturaAcordo";

class ListaFatura extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.estornar = this.estornar.bind(this);
        this.clear = this.clear.bind(this);
        this.handleClickEstornar = this.handleClickEstornar.bind(this);
        this.handleClickRegistrar = this.handleClickRegistrar.bind(this);
        this.handleClickBaixar = this.handleClickBaixar.bind(this);
        this.handleClickRelatorioContasPagar = this.handleClickRelatorioContasPagar.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLista = this.getLista.bind(this);
        this.registrar = this.registrar.bind(this);
        this.handleClickGetCobrancas = this.handleClickGetCobrancas.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.handleCheckAllFatura = this.handleCheckAllFatura.bind(this);
        this.handleCheckFatura = this.handleCheckFatura.bind(this);
        this.handleClickGerarAcordo = this.handleClickGerarAcordo.bind(this);
        this.podeGerarAcordoItemSelecionados = this.podeGerarAcordoItemSelecionados.bind(this);

        this.state = this.getInitState(props);

        this.state.filtroPagamento = [
            { value: -1, label: 'Todos' },
            { value: 1, label: 'Pagos' },
            { value: 0, label: 'Não Pagos' },
        ];

        this.state.filtroVencimento = [
            { value: -1, label: 'Todos' },
            { value: 1, label: 'Vencido' },
            { value: 0, label: 'Em Dia' },
        ];

        this.state.filtroTipo = [
            { value: "T", label: "Todos" },
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ];

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];

        this.cache = {
            active: true,
            id: "cache-lista-fatura",
            clear: true,
            state: {
                model: {
                    busca: true,
                    idFatura: true,
                    idCobranca: true,
                    idOrdem: true,
                    nrNota: true,
                    dtPagamentoStart: true,
                    dtPagamentoEnd: true,
                    dtStart: true,
                    dtEnd: true,
                    idRepresentante: true,
                    idProprietario: true,
                    idFaturaStatus: true,
                    idTipoProdutoServico: true,

                    pagina: true,
                    rowCount: true,

                    flPago: true,
                    flVencido: true,
                    anoReferencia: true,
                    mesReferencia: true,
                    flFiltroCdTipo: true,
                    flFiltroData: true,
                    flFiltroOrdem: true,
                    flFiltroTipoOrdem: true,
                }
            }
        };
    }

    getInitState(props) {

        return {
            data: [],
            faturas : [],
            model: {
                busca: "",
                idFatura: "",
                idCobranca: "",
                idOrdem: "",
                nrNota: "",
                dtPagamentoStart: "",
                dtPagamentoEnd: "",
                dtStart: "",
                dtEnd: "",
                idRepresentante: "",
                idProprietario: "",
                idFaturaStatus: "",
                idTipoProdutoServico: "",

                pagina: 0,
                rowCount: 50,

                flPago: -1,
                flVencido: -1,
                anoReferencia: "",
                mesReferencia: "",
                flFiltroCdTipo: "T",
                flFiltroData: "V",
                flFiltroOrdem: "V",
                flFiltroTipoOrdem: "DESC",
            },
            tipos: [
                { value: "P", label: "PRODUTO" },
                { value: "S", label: "SERVIÇO" },
            ],

            filtroData: [
                { value: 'V', label: 'Vencimento' },
                { value: 'F', label: 'Fatura' },
            ],

            filtroOrdem: [
                { value: 'V', label: 'Próx. Vencimento' },
                { value: 'F', label: 'Fatura' },
            ],
            
            filtroTipoOrdem: [
                { value: 'ASC', label: 'Crescente' },
                { value: 'DESC', label: 'Decrescente' },
            ],

            filtroRowCount: [
                { value: 50, label: "50 Registros" },
                { value: 100, label: "100 Registros" },
                { value: 200, label: "200 Registros" },
                { value: 500, label: "500 Registros" },
                { value: 1000, label: "1000 Registros" },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount(this.getLista);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    getLista(merge = false) {
        if (merge) {
            FaturaService.list(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                FaturaService.list(this.state.model, (response) => {
                    this.setState({ data: response, faturas: [] });
                });
            });
        }
    }

    handleDoubleClick(e, item){
        this.props.navigate('/CadFatura/' + item.idFatura);
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    handleClickEstornar(item) {
        EventBus.dispatch("modal-fatura-open", {
            id: item.idFatura,
            config: {
                title: "Estornar Fatura?",
                message: <>Deseja realmente estornar a fatura: <strong>{item.idFatura} </strong>?</>,
                btnConfirmText: "Sim, estornar a fatura",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: this.estornar
            }
        })
    }

    estornar(item) {
        FaturaService.estornar(item.id, (response) => {
            this.getLista();
        });
    }

    handleClickRegistrar(cobranca) {
        EventBus.dispatch("modal-fatura-open", {
            id: cobranca.idCobranca,
            config: {
                title: "Registrar Boleto?",
                message: <>Deseja realmente registrar o boleto para a fatura: <strong>{cobranca.idCobranca} </strong>?</>,
                btnConfirmText: "Sim, registrar boleto",
                description: "Esta é uma ação é irreversível!",
                style: "warning",
                confirm: this.registrar
            }
        })
    }

    registrar(item) {
        BoletoService.registrar(item.id, (response) => {
            this.getLista();
        });
    }

    handleClickBaixar(item) {

        EventBus.dispatch("modal-add", {
            title: 'Baixa Manual',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idFatura: item.idFatura,
                idCobranca: item.idCobranca
            },
            form: FormBaixa,
            onClose: () => {
                this.getLista();
            },
            onSave: () => {
                this.getLista();
            }
        });
    }

    onSubmit(event) {
        this.getLista();
    }

    getStatus(item) {
        if (parseInt(item.flPago) === 1) {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-success "> Pago </span>
        }

        if (parseInt(item.flVencido) === 1) {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-danger "> Vencido </span>
        }

        return <span className="rounded-pill badge w-100 fs-7 text-bg-warning "> Em Dia </span>
    }

    getStatusNF(dsSituacao, cdTipo = "") {
        
        if (dsSituacao === "Cancelada" || dsSituacao === "Estornada") {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-danger"> {dsSituacao} </span>
        } else if (dsSituacao === "Emitida DANFE") {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-primary"> {dsSituacao} </span>
        } else if (dsSituacao === "Autorizada") {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-success"> {dsSituacao} </span>
        } else if (dsSituacao === "Pendente") {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-warning"> {dsSituacao} </span>
        } else if (dsSituacao === "Emitida") {
            if(cdTipo === "P"){
                return <span className="rounded-pill badge w-100 fs-7 text-bg-warning"> {dsSituacao} </span>
            }else if(cdTipo === "S"){
                return <span className="rounded-pill badge w-100 fs-7 text-bg-primary"> {dsSituacao} </span>
            }
        }

        return <span className="rounded-pill badge w-100 fs-7 text-bg-secondary">{dsSituacao}</span>
    }

    handleClickGetCobrancas(item, index) {
        if (this.state.data[index]?.cobrancas?.length > 0) {
            this.setState(state => {
                state.data[index].cobrancas = [];
                return state;
            });
        } else {
            CobrancaService.getAllByFatura(item.idFatura, (response) => {
                this.setState(state => {
                    state.data[index].cobrancas = response;
                    return state;
                });
            });
        }
    }

    handleImprimirIndividual(idFatura) {
        EventBus.dispatch("modal-add", {
            title: 'Impressão da Fatura',
            sizeModal: 'modal-lg',
            scrollable: false,
            config: {
                isModal: true,
                idFaturas: [idFatura]
            },
            form: FormRelatorioFatura001
        });
    }

    handleClickRelatorioContasPagar() {
        EventBus.dispatch("modal-add", {
            title: "Relatório de Contas a Pagar",
            sizeModal: 'modal-lg',
            form: FormRelatorioFinanceiro002
        });
    }

    handleCheckAllFatura(event) {
        if (event.target.checked) {
            this.setState(state => {
                state.faturas = [];
                state.data.forEach(item => {
                    state.faturas.push(item.idFatura);
                });
                return state;
            });
        } else {
            this.setState(state => {
                state.faturas = [];
                return state;
            });
        }
    }

    handleCheckFatura(event, item) {
        if (event.target.checked) {
            this.setState(state => {
                state.faturas.push(item.idFatura);
                return state;
            });
        } else {
            this.setState(state => {
                state.faturas.splice(state.faturas.findIndex(idFatura => parseInt(idFatura) === parseInt(item.idFatura)), 1);
                return state;
            });
        }
    }

    podeGerarAcordoItemSelecionados() {

        if (this.state.faturas.length > 0) {
            var fatura = this.state.data.find(o => parseInt(this.state.faturas[0]) === parseInt(o.idFatura));
            if (fatura) {
                
                var idProprietario = fatura.idProprietario;
                var idOrdemTipo = fatura.idOrdemTipo;
                var pessoa = {};

                return this.state.faturas.length === (this.state.faturas.filter(idFatura => {
                    var item = this.state.data.find(o => parseInt(idFatura) === parseInt(o.idFatura));
                    return ( 
                        parseFloat(item.vlAberto) > 0
                        && parseInt(item?.idProprietario) === parseInt(idProprietario) 
                        && parseInt(item?.idOrdemTipo) === parseInt(idOrdemTipo) 
                        //&& (parseInt(item?.idOrdemTipo) === 1) //REPRESENTANTE 
                        && (parseInt(item?.idOrdemTipo) === 1 || (parseInt(item?.idOrdemTipo) === 2)) //REPRESENTANTE OU CLIENTE FINAL
                        
                        //VALIDAR QUANDO FOR CLIENTE FINAL, TEM QUE SER O MESMO CPF OU CNPJ
                    )
                }).length);
            }
        }

        return false;
    }

    handleClickGerarAcordo(){

        var fatura = this.state.data.find(o => parseInt(this.state.faturas[0]) === parseInt(o.idFatura));

        EventBus.dispatch("modal-add", {
            title: "Formulário de Acordo",
            sizeModal: 'modal-xl',
            fullScreen : true,
            config: {
                isModal: true,
                idProprietario : fatura.idProprietario,
                idOrdemTipo : fatura.idOrdemTipo,
                idFaturas: this.state.faturas
            },            
            form: FormFaturaAcordo,
            onSave: (response) => {
                this.props.navigate('/Faturar/' + response.data.idOrdem);
            }
        });
    }    

    getTotalItem(prop) {
        return parseFloat(this.state.data.reduce((soma, item) => soma + (parseFloat(item[prop]) || 0), 0)).toFixed(2);
    }

    render() {
        return (
            <div className="p-4 my-2 py-2 w-100">
                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100">Lista de Fatura(s)</h4>

                    {Permissao.getInstance().getPermissao(130) ?
                        <BtnButton disabled={this.state.faturas.length === 0 || !this.podeGerarAcordoItemSelecionados()} className="btn-outline-secondary text-nowrap d-none d-sm-block" onClick={this.handleClickGerarAcordo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
                                <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.2 2.2 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
                            </svg> Acordo
                        </BtnButton> : <></>}

                    {Permissao.getInstance().getPermissao(99) ?
                        <BtnButton className="btn-outline-primary text-nowrap d-none d-sm-block" onClick={this.handleClickRelatorioContasPagar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                            </svg> Relatório de Contas a Pagar
                        </BtnButton> : <></>}
                </div>
                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row mb-3 g-2">
                        <InputNumber md={1} sizing="sm" label="Fatura" placeholder="9999" value={this.state.model.idFatura} name="idFatura" onChange={this.handleChange} min={1} opcionalLabel={false} />
                        <InputNumber md={1} sizing="sm" label="Cobrança" placeholder="9999" value={this.state.model.idCobranca} name="idCobranca" onChange={this.handleChange} min={1} opcionalLabel={false} />
                        <InputNumber md={1} sizing="sm" label="Ordem" placeholder="9999" value={this.state.model.idOrdem} name="idOrdem" onChange={this.handleChange} min={1} opcionalLabel={false} />
                        <InputText md={1} sizing="sm" label="NF" placeholder="9999" value={this.state.model.nrNota} name="nrNota" onChange={this.handleChange} opcionalLabel={false} />
                        <InputNumber md={1} sizing="sm" label="Ano" placeholder="9999" value={this.state.model.anoReferencia} name="anoReferencia" onChange={this.handleChange} opcionalLabel={false} />
                        <SelectMes showBtns={false} sizing="sm" md={1} label="Mês" placeholder="Mês" name="mesReferencia" value={this.state.model.mesReferencia} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flPago" value={this.state.model.flPago} label="Status Pagamento" options={this.state.filtroPagamento} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flVencido" value={this.state.model.flVencido} label="Status Vencimento" options={this.state.filtroVencimento} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flFiltroCdTipo" value={this.state.model.flFiltroCdTipo} label="Tipo da Ordem" options={this.state.filtroTipo} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />

                        <BtnGroup md={2} sizing="sm" className="w-100" name="flFiltroData" value={this.state.model.flFiltroData} label="Data de" options={this.state.filtroData} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <InputDate md={2} sizing="sm" label={`Data ${this.state.filtroData.find(i => i.value === this.state.model.flFiltroData)?.label} Inicial`} value={this.state.model.dtStart} name="dtStart" onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <InputDate md={2} sizing="sm" label={`Data ${this.state.filtroData.find(i => i.value === this.state.model.flFiltroData)?.label} Final`} value={this.state.model.dtEnd} name="dtEnd" onChange={(e) => { this.handleChange(e, this.onSubmit) }} min={this.state.model.dtStart} opcionalLabel={false} />
                        <SelectRepresentanteByUsuario showBtnNewForm={false} sizing="sm" md={3} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <SelectProprietarioByUsuario showBtnNewForm={false} sizing="sm" md={3} label="Proprietário" placeholder="Selecione o Proprietario" name="idProprietario" value={this.state.model.idProprietario} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        
                        <Select label="Registros por Página" md={2} sizing="sm" value={this.state.model.rowCount} required={true} options={this.state.filtroRowCount} name="rowCount" onChange={(e) => { this.handleChange(e, this.onSubmit) }}></Select>
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flFiltroOrdem" value={this.state.model.flFiltroOrdem} label="Ordenar por data de:"  options={this.state.filtroOrdem} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flFiltroTipoOrdem" value={this.state.model.flFiltroTipoOrdem} label="Tipo da Ordenação:"  options={this.state.filtroTipoOrdem} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-10">
                            <div className=" input-group">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control form-control-sm" autoFocus autoComplete="off" name="busca" placeholder="Digite a sua busca" value={this.state.model.busca} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="col d-flex align-items-end bd-highlight">
                            <button className="ms-auto btn btn-outline-primary btn-sm mx-2 text-nowrap" type="submit"> Buscar </button>
                            <button type="button" className="btn btn-outline-danger btn-sm text-nowrap" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar
                            </button>
                        </div>
                    </div>
                </form>

                <div className="table-responsive-sm">
                    <table className="table table-sm caption-top table-striped" style={{ fontSize: ".850em" }}>
                        <caption className="text-end">
                            {this.state.data.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th className="d-none d-md-table-cell"></th>
                                <th className="d-none d-md-table-cell">
                                    <input type="checkbox" checked={this.state.faturas.length === this.state.data.length} className="form-check-input form-check-input-size-20" onChange={this.handleCheckAllFatura} />
                                </th>
                                <th className="d-none d-md-table-cell"> ID </th>
                                <th className="col-md-6"> Nome do Cliente </th>
                                <th className="text-center d-none d-md-table-cell text-nowrap"> Prox. Venc. </th>
                                <th className="text-center d-none d-md-table-cell text-nowrap"> Dt. Fatura </th>
                                <th className="text-center d-none d-md-table-cell"> Ordens </th>
                                <th className="text-center d-none d-md-table-cell"> Parcelas </th>
                                <th className="col-md-1 text-end"> Fatura</th>
                                <th className="col-md-1 text-end d-none d-md-table-cell"> Pago </th>
                                <th className="col-md-1 text-end d-none d-md-table-cell"> Desconto </th>
                                <th className="col-md-1 text-end d-none d-md-table-cell"> Aberto </th>
                                <th className="col-md-1 text-center d-none d-md-table-cell"> NF </th>
                                <th className="col-md-1 text-center d-none d-md-table-cell"> Status </th>
                                <th className="text-end">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, indexFatura) => (
                                <React.Fragment key={indexFatura}>
                                    <tr onDoubleClick={e => this.handleDoubleClick(e, item)}>
                                        <td className="fs-9 text-end text-secondary align-middle d-none d-md-table-cell">
                                            {indexFatura + 1}.
                                        </td>
                                        <td className="align-middle text-center text-nowrap d-none d-md-table-cell">
                                            <input type="checkbox" className="form-check-input form-check-input-size-20" checked={this.state.faturas.findIndex(o => parseInt(o) === parseInt(item.idFatura)) !== -1} onChange={(e) => { this.handleCheckFatura(e, item) }} />
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            <div>
                                                <span className="rounded-pill badge w-100 fs-7 text-bg-secondary"> #{item.idFatura} </span>
                                            </div>
                                            <div className="mt-1">
                                                {Boolean(item.flProduto) ? <span className="rounded-pill badge w-100 fs-8 text-bg-warning">PRODUTO</span> : <></>}
                                                {Boolean(item.flServico) ? <span className="rounded-pill badge w-100 fs-8 text-bg-primary">SERVIÇO</span> : <></>}
                                                {parseInt(item.idAcordo) > 0 ? <span className="rounded-pill badge w-100 fs-8 text-bg-danger">ACORDO</span> : <></>}
                                            </div>
                                        </td>
                                        <td className="align-middle text-uppercase">
                                            <span className="fw-semibold">{item.faturaPessoaNome}</span>
                                            <div className="fst-italic">{item.nomeOrdemTipo}</div>

                                            <div className="fs-8">
                                                Pessoa {this.state.tipoPessoa.find(i => i.value === item.faturaPessoaCdTipoPessoa)?.label}
                                            </div>

                                            <div className="fs-8 fst-italic">
                                                {item.faturaPessoaCdTipoPessoa === "F" ? Util.formataCPF(item.faturaPessoaCpf) : ""}
                                                {item.faturaPessoaCdTipoPessoa === "J" ? Util.formataCnpj(item.faturaPessoaCnpj) : ""}
                                            </div>
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            {Util.date2Br(item.minDtVencimento)}
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            {Util.date2Br(item.dataFatura)}
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            {item.nrOrdem}
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            {item.nrParcelas}x
                                        </td>
                                        <td className="align-middle text-end text-end text-primary fw-bold">
                                            <MostraValor>{Util.floatToReais(item.vlFatura)}</MostraValor>
                                        </td>
                                        <td className="align-middle text-end d-none d-md-table-cell text-success fw-bold">
                                            <MostraValor>{Util.floatToReais(item.vlBaixa)}</MostraValor>
                                        </td>
                                        <td className="align-middle text-end d-none d-md-table-cell text-danger fw-bold">
                                            <MostraValor>{Util.floatToReais(item.vlDesconto)}</MostraValor>
                                        </td>
                                        <td className={`align-middle text-end d-none d-md-table-cell fw-bold ${(item.vlAberto) > 0 ? "text-danger" : ""}`}>
                                            <MostraValor>{Util.floatToReais(item.vlAberto)}</MostraValor>
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            <div>
                                                {this.getStatusNF(item.dsNFProdutoSituacao, "P")}
                                            </div>
                                            <div className="pt-1">
                                                {this.getStatusNF(item.dsNFServicoSituacao, "S")}
                                            </div>
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            {this.getStatus(item)}
                                        </td>
                                        <td className="">
                                            <Link to={`/CadFatura/${item.idFatura}`} className="btn btn-outline-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                </svg>
                                            </Link>

                                            <BtnButton className="btn-outline-success mt-1" onClick={e => { this.handleClickGetCobrancas(item, indexFatura) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-coin" viewBox="0 0 16 16">
                                                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                                                </svg>
                                            </BtnButton>

                                        </td>
                                    </tr>
                                    {item.cobrancas?.length > 0 ?
                                        <tr>
                                            <td className="d-none d-md-table-cell"></td>
                                            <td colSpan={11} className="bg-white">
                                                <table className="table table-sm mb-0 w-100 mb-5 my-2 bg-white">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="text-center">Parcela</th>
                                                            <th scope="col" className="col-md-2 text-center">Vencimento</th>
                                                            <th scope="col" className="col-md-2 text-center">Pagamento</th>
                                                            <th scope="col" className="col-md-2 text-end">Valor Cobrança</th>
                                                            <th scope="col" className="col-md-2 text-end d-none d-md-table-cell">Valor Pago</th>
                                                            <th scope="col" className="col-md-2 text-end d-none d-md-table-cell">Valor Desconto</th>
                                                            <th scope="col" className="col-md-2 text-end d-none d-md-table-cell">Valor Aberto</th>
                                                            <th scope="col" className="col-md-1 text-center d-none d-md-table-cell">Status</th>
                                                            <th className="text-end">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            item.cobrancas.map((cobranca, indexCobranca) => (
                                                                <tr key={cobranca.idCobranca}>
                                                                    <td className="text-center">
                                                                        {cobranca.nrParcela}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {Util.date2Br(cobranca.dtVencimento)}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {Util.date2Br(cobranca.dtPagamento)}
                                                                    </td>
                                                                    <td className="align-middle text-end text-end text-primary fw-bold">
                                                                        <MostraValor>{Util.floatToReais(cobranca.vlCobranca)}</MostraValor>
                                                                    </td>
                                                                    <td className="align-middle text-end d-none d-md-table-cell text-success fw-bold">
                                                                        <MostraValor>{Util.floatToReais(cobranca.vlBaixa)}</MostraValor>
                                                                    </td>
                                                                    <td className="align-middle text-end d-none d-md-table-cell text-danger fw-bold">
                                                                        <MostraValor>{Util.floatToReais(cobranca.vlDesconto)}</MostraValor>
                                                                    </td>
                                                                    <td className={`align-middle text-end d-none d-md-table-cell fw-bold ${(cobranca.vlAberto) > 0 ? "text-danger" : ""}`}>
                                                                        <MostraValor>{Util.floatToReais(cobranca.vlAberto)}</MostraValor>
                                                                    </td>
                                                                    <td className="align-middle text-center d-none d-md-table-cell">
                                                                        {this.getStatus(cobranca)}
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        {Boolean(cobranca.flPago) === 0 && cobranca.flTemCobrancaTipoBaixa ?
                                                                            <Link className="btn btn-success text-nowrap" to={'/pagamento/' + cobranca.uid} rel="noopener noreferrer" target="_blank">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-qr-code" viewBox="0 0 16 16">
                                                                                    <path d="M2 2h2v2H2V2Z" />
                                                                                    <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                                                                                    <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                                                                                    <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z" />
                                                                                    <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
                                                                                </svg> Pagar
                                                                            </Link> : <></>
                                                                        }
                                                                    </td>
                                                                    {/*<td>
                                                                        {Permissao.getInstance().getPermissoes([31, 32]) ?
                                                                            <div className="dropdown dropstart">
                                                                                <button className="btn btn-outline-dark" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                                    </svg>
                                                                                </button>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                    {Permissao.getInstance().getPermissao(32) ?
                                                                                        <li>
                                                                                            <button type="button" className="dropdown-item" href="#" onClick={() => { this.handleClickBaixar(cobranca) }}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-exchange" viewBox="0 0 16 16">
                                                                                                    <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
                                                                                                </svg> Baixar Manual
                                                                                            </button>
                                                                                        </li>
                                                                                        : <></>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            : <></>
                                                                        }
                                                                    </td>*/}
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        : <></>
                                    }
                                </React.Fragment>
                            ))}

                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="d-none d-md-table-cell" colSpan={8}></td>

                                <td className="d-none d-md-table-cell align-middle text-end text-end text-primary fw-bold">
                                    <MostraValor>{Util.floatToReais(this.getTotalItem("vlFatura"))}</MostraValor>
                                </td>
                                <td className="d-none d-md-table-cell align-middle text-end d-none d-md-table-cell text-success fw-bold">
                                    <MostraValor>{Util.floatToReais(this.getTotalItem("vlBaixa"))}</MostraValor>
                                </td>
                                <td className="d-none d-md-table-cell align-middle text-end d-none d-md-table-cell text-danger fw-bold">
                                    <MostraValor>{Util.floatToReais(this.getTotalItem("vlDesconto"))}</MostraValor>
                                </td>
                                <td className="d-none d-md-table-cell align-middle text-end d-none d-md-table-cell fw-bold text-danger">
                                    <MostraValor>{Util.floatToReais(this.getTotalItem("vlAberto"))}</MostraValor>
                                </td>

                                <td colSpan={2}></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div className="text-center pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>

                <ModalConfirmacao id="modal-fatura" />
            </div >
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <ListaFatura {...props} navigate={navigate} params={params} />
}

export default With