import Http from "../controllers/Http";

export default class GenericService {

    static urlBase = "/generic/";

    static getForCombo(controller = "", filter = {}, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + controller + "/forCombo", filter)
            .then(fnSucess)
            .catch(fnError);
    }    
}
